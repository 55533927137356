<template>
  <div>
    <editor v-model="myValue" :init="init" :disabled="disabled"></editor>
  </div>
</template>

<script>
import axios from "axios";
import tinymce from "tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/icons/default";
export default {
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default:
        "print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern autosave",
    },
    toolbar: {
      type: [String, Array],
      default:
        "fullscreen undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
                styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
                table image media charmap hr pagebreak insertdatetime print preview | code selectall searchreplace visualblocks | indent2em lineheight formatpainter axupimgs",
    },
  },
  data() {
    return {
      public: process.env.BASE_URL,
      init: {
        language_url: process.env.BASE_URL + "tinymec/langs/zh_CN.js", //如果语言包不存在，指定一个语言包路径
        language: "zh_CN", //语言
        skin_url: process.env.BASE_URL + "tinymec/skins/ui/oxide", //如果主题不存在，指定一个主题路径
        // content_css: "/static/plugins/tinymce/mycontent.css",
        height: "700px",
        // width: this.calcWidth(),
        plugins: this.plugins, //插件
        toolbar: this.toolbar, //工具栏
        branding: false, //技术支持(Powered by Tiny || 由Tiny驱动)
        menubar: true, //菜单栏
        theme: "silver", //主题
        zIndex: 1101,
				images_upload_url:this.baseUrl+ 'admin/Article/uploadFile',
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;黑体=黑体;仿宋=仿宋;楷体=楷体;隶书=隶书;幼圆=幼圆;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings',
      },
      myValue: this.value,
      imgform: "",
    };
  },
  mounted() {
    console.log(this.public);
    tinymce.init({});
  },
  methods: {
    // calcWidth() {
    //   return document.body.clientWidth / 2 + "px";
    // },

    upImg(blobInfo, success, failure) {
      var formData = new FormData();
      formData.append("img", blobInfo.blob());
      this.imgform = formData;
      console.log(this.imgform);
      axios({
        method: "post",
        url: this.baseUrl + "admin/Article/uploadFile",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      }).then((res) => {
        console.log(res.data);
        success(this.baseUrl + res.data); //获取返回图片的路径
        console.log(failure);
      });
    },
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue() {
      this.$emit("input", this.myValue);
    },
  },
};
</script>